#loginPanel{
  max-width:80%;
  min-height:70%;
  text-align: center;
  align-items: center;
  position: absolute;
  left:50%;
  transform: translate(-50%,-50%);
  top: 50%;
}

.loginIntro{
  font-size: 1.2rem;
  color:#191919;
  margin:2vh;
  margin-left: 4vw;
  margin-right: 4vw;
  text-align: center;
  font-family: 'Montserrat';
}

.loginLogo{
  height: 15vh;
  max-width: 70%;
  margin-top: 2vh;
}

.loginContainer{
  display: flex;
  flex-direction: row;
  height:100%;
  width:100%;
}

.loginColumn{
  height:100%;
  flex:1;
  background:white;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loginColumnImage{
  height:100%;
  flex:1;
  background-image: url('../../images/photo-gradient.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.loginColumnImageText{
  position: absolute;
  top:45%;
  transform: translate(0%,-50%);
  left:3vw;
  width:90%;
  text-align: left;
}

.loginColumnImageTextHeader{
  font-family: 'Montserrat';
  color:white;
  font-weight: bold;
  font-size: 1.7rem;
  text-align: left;
}

.loginColumnImageTextContent{
  font-family: 'Montserrat';
  color:white;
  font-size: 1.2rem;
  text-align: left;
  margin-top: 3vh;
  opacity:0.9;
}


.demoButton{
  border-radius:10px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:20px;
  font-family: "Comfortaa", cursive;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:30%;
  margin-top: 6vh;
  border:0px;
  color:#ec008e;
  background:white;
  text-align: center;
}
.demoButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitButtonContainer{
  justify-content: flex-end;
  align-items: flex-end;
  width:80%;
  position: relative;
  left:10%;
}

#loginHeaderMain{
  width:70%;
  border-radius:12px;
  margin-top: 3vh;
  text-align: center;
  padding:30px;
  padding-top: 1.4vh;
  padding-bottom: 1.4vh;
  color:white;
  font-size: 1.2rem;
  background:#6F87FF;
  font-family: "Comfortaa", cursive;
  position: relative;
  left:50%;
  transform: translate(-50%,0%);
}

.loginLinks{
  font-size: 0.9rem;
  color:#5040e2;
  opacity:0.7;
  font-family: 'Open Sans',sans-serif;
  margin-top: 14vh;
  width:80%;
  position: relative;
  left:10%;
  text-align: right;
}

.loginLink{
  margin-left: 0vw;
  display: inline-block;
  vertical-align: middle;
  align-self: flex-end;
  text-decoration: none;
  color:#0f6194;
}

.loginError{
  font-size: 0.9rem;
  color:#d90f4f;
  opacity:0.7;
  font-family: 'Montserrat';
  margin-top: 3vh;
}

.loginCopyright{
  font-size: 0.8rem;
  color:#191919;
  opacity:0.7;
  font-family: 'Montserrat';
  position: absolute;
  bottom:20px;
  left:0%;
  width:100%;
  text-align: center;
}

@media only screen and (max-width: 768px) {

  .loginPanel{
    transform: translate(-50%, 0%);
    top:20vh;
    left:50%;
  }


}

@font-face {
  font-family: GothamPro;
  src: url("../../styles/GothamPro-Medium.ttf");
}

.sideMenu{
  background:#20b0ab;
  border-radius:0px;
  padding:10px;
  font-family: GothamPro;
  padding-top: 1.4vh;
  padding-bottom: 1.4vh;
  width:70%;
  margin-top: 0vh;
  border:0px;
  display: flex;
  flex:0.8;
  flex-direction: column;
  height:calc(100%);
  text-align: center;
  z-index:20;
}

.sideMenuLogo{
  width:70%;
  margin-top: 1vh;
  display: inline-block;
  position: relative;
  left:15%;
  margin-bottom: 4vh;
  border-radius: 20px;
  padding:5px;
  background: white;
}

.sideMenuSeperator{
  height:1px;
  margin-top: 0vh;
  margin-bottom: 1vh;
  border-radius:10px;
  width:100%;
  background:#ebecf0;
}

.sideMenuWelcome{
  color:#003bde;
  font-size: 1rem;
  font-family: GothamPro;
  padding:0.4vw;
  padding-bottom: 1.8vh;
}

.sideMenuContent{
  width:100%;
  flex:1;
  overflow-y:scroll;
  text-align: left;
}

.sideMenuLogoutButton{
  width:100%;
  padding-top: 1.1vh;
  padding-bottom: 1.1vh;
  background:#1be0af;
  color:white;
  font-family: GothamPro;
  font-size: 0.8rem;
  border-radius:7px;
  box-shadow: 0px 6px 10px rgba(10,0,180,0.3);
  margin-top: 1vh;
  margin-bottom: 2vh;
  text-align: center;
}
.sideMenuLogoutButton:hover{
  cursor:pointer;
  opacity:0.6;
}

.sideMenuLogoutButtonIcon{
  width:1vw;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1vw;
  margin-left: 1vw;
}

.sideMenuLogoutButtonText{
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

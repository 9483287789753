
.submitButton{
  border-radius:6px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:20px;
  font-family: "Open Sans", sans-serif;
  padding-top: 1.4vh;
  padding-bottom: 1.4vh;
  width:30%;
  margin-top: 6vh;
  border:0px;
  color:white;
  background:#20b0ab;
  position: absolute;
  right:0px;
}
.submitButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitButtonText{
  vertical-align: middle;
  display: inline-block;
}

.submitButtonIcon{
  height:2.2vh;
  vertical-align: middle;
  display: inline-block;
}
